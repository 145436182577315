import { useLogoutMutation } from "../../app/api/service"
import { Cookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"

export function LogoutButton() {
  const [logout, { isLoading }] = useLogoutMutation()
  const navigate = useNavigate()

  return (
    <LoadingButton
      loading={isLoading}
      onClick={async () => {
        logout(undefined)
          .unwrap()
          .then((_) => {
            const cookies = new Cookies(null, { path: "/" })
            cookies.remove("access_token")
            cookies.remove("refresh_token")
            navigate("/")
          })
      }}
    >
      Log Out
    </LoadingButton>
  )
}
