import { useProfile } from "../../app/api/service"
import { CreateApplicationArea } from "../../features/applications/CreateApplication"
import React from "react"
import { AppListArea } from "../../features/applications/AppList"
import { PageLayout } from "../../components/layout/PageLayout"
import { Navigate } from "react-router-dom"
import { DashboardNav } from "../../components/nav/DashboardNav"

export function ApplicationsPage() {
  const { isError } = useProfile()

  if (isError) {
    return <Navigate to={"/login"} />
  }

  return (
    <PageLayout>
      <DashboardNav />
      <CreateApplicationArea />
      <AppListArea />
    </PageLayout>
  )
}
