import React, { useCallback, useState } from "react"
import { useUpdateApplicationMutation } from "../../app/api/service"
import { ApplicationURIEntry } from "./ApplicationURIEntry"
import {
  Button,
  Divider,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Add } from "@mui/icons-material"
import { Application, ApplicationStatus } from "../../types/application"
import LoadingButton from "@mui/lab/LoadingButton"
import { useAlert } from "../../components/alert/AlertProvider"
import { CutPaper } from "../../components/paper/CutPaper"

export function UpdateApplicationArea(props: { app: Application }) {
  const [name, setName] = useState<string>(props.app.name)
  const [status, setStatus] = useState<ApplicationStatus>(props.app.status)
  const [callbackURIs, setCallbackURIs] = useState<string[]>(
    props.app.callback_uris,
  )

  const nameValid = name.length >= 3
  const urlsValid =
    callbackURIs.find((u) => {
      if (u.length === 0) {
        return true
      }

      try {
        new URL(u)
        return false
      } catch {
        return true
      }
    }) === undefined && callbackURIs.length > 0

  const [updateApp, { isLoading }] = useUpdateApplicationMutation()
  const issueAlert = useAlert()

  const signUp = useCallback(() => {
    if (!name) {
      setName("")
      return
    }

    updateApp({
      client_id: props.app.id,
      body: {
        callback_uris: callbackURIs,
        name: name.trim(),
        status: status,
      },
    })
      .unwrap()
      .then()
      .catch((e) =>
        issueAlert({
          message: "Failed to update app",
          severity: "error",
        }),
      )
  }, [issueAlert, callbackURIs, name, props.app.id, status, updateApp])

  return (
    <Grid item xs={12} lg={6}>
      <CutPaper>
        <Stack direction={"column"} spacing={1}>
          <Typography variant="h5">Update Application</Typography>
          <Divider />
          <TextField
            label={"Name"}
            onChange={(event) => setName(event.target.value)}
            value={name || ""}
            type={"text"}
            error={!nameValid}
          />
          <FormLabel>Callback URIs</FormLabel>
          <Stack spacing={1} direction={"column"}>
            {callbackURIs.map((uri, index) => (
              <ApplicationURIEntry
                hideRemove={index === 0}
                onRemove={() =>
                  setCallbackURIs((old) => {
                    const newURIs = [...old]
                    newURIs.splice(index, 1)
                    return newURIs
                  })
                }
                value={callbackURIs[index]}
                onUpdate={(newValue) =>
                  setCallbackURIs((old) => {
                    const newURIs = [...old]
                    newURIs[index] = newValue
                    return newURIs
                  })
                }
              />
            ))}

            {callbackURIs.length < 16 && (
              <Button
                startIcon={<Add />}
                aria-label="Add URI"
                onClick={() => setCallbackURIs((old) => [...old, ""])}
              />
            )}
          </Stack>
          <ToggleButtonGroup
            value={status}
            exclusive
            onChange={(_, newValue) => {
              if (newValue) {
                setStatus(newValue)
              }
            }}
          >
            <ToggleButton value={ApplicationStatus.Active} color={"success"}>
              Active
            </ToggleButton>
            <ToggleButton value={ApplicationStatus.Inactive} color={"error"}>
              Inactive
            </ToggleButton>
          </ToggleButtonGroup>
          <LoadingButton
            onClick={signUp}
            loading={isLoading}
            disabled={(name?.length || 0) < 3 || !nameValid || !urlsValid}
          >
            Update Application
          </LoadingButton>
        </Stack>
      </CutPaper>
    </Grid>
  )
}
