export interface User {
  discord_sf: string | null
  email: string | null
  id: string
  rsi_id: string
  sentinel: string
  status: UserStatus
}

export enum UserStatus {
  WaitingValidation = "WaitingValidation",
  Active = "Active",
  Inactive = "Inactive",
  Unknown = "Unknown",
}

export interface NewUserBody {
  email: string
  password: string
}

export interface EmailLoginBody {
  email: string
  password: string
}

export interface TokenLoginBody {
  refresh_token: string
  client_id: string
  grant_type: "refresh_token"
}

export interface BaseResponse<T> {
  payload: T
  status: number
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  expires_in: number
  token_type: string
}

export interface LinkResponse {
  id: string
  sentinel: string
}
